import React, { useState, useEffect } from 'react';
import SystemStatusStickyHeader from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusOverview/StickyHeader';
import UserOverview from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusUser/Overview';
import UserGroups from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusUser/Groups';
import { Link } from 'gatsby';


export default function StatusUser({props}) {

    const [datePicker, setDatePicker] = useState(new Date);

    return (
        <div className="content">
            <SystemStatusStickyHeader
                title="Status: Users"
                onChange={setDatePicker}
                selectedDate={datePicker}
                status={'pass'}
            >
            <h6> <Link to="/status/overview"> Overview / </Link> Users </h6>
            </SystemStatusStickyHeader>
            <div className='statuscontent'>
                <div className='columns is-multiline'>
                    <div className='column is-6'>
                        <UserOverview/>
                    </div>
                    <div className='column is-6'>
                        <UserGroups />
                    </div>
                </div>
            </div>
        </div>
    )
}
